<template>
  <base-layout>
    <ion-searchbar @click="filterbyLetter('all')"
        show-cancel-button="focus"
        @ionChange="filter(search)"
        :value="search"
        @ionInput="search = $event.target.value;"
    ></ion-searchbar>
    <ion-content class="has-header has-subheader">

      <div class="row px-3 mb-2" style="background: #E30513;">      
        <div class="col p-2" >
        <div class="row p-2">
          <template  v-for="exhibitor in exhibitors" :key="exhibitor.id">
            <div class="col px-2" style="text-align: center; color: #E30513; background:white; border-radius:25px" v-if="exhibitor.isLetter && selectedWord == exhibitor.letter"> <b> {{exhibitor.letter}} </b> </div>
            <div class="col px-2" style="text-align: center; color: white"  @click="filterbyLetter(exhibitor.letter)" v-if="exhibitor.isLetter && selectedWord != exhibitor.letter"> <b> {{exhibitor.letter}} </b> </div>
          </template>
        </div>
        </div>
      </div>

      <div class="container-fluid p-0 p-md-3"> 
        <div class="row row-cols-1 row-cols-md-3 row-cols-lg-4 row-cols-xl-6 g-0 g-md-4 row-eq-height justify-content-start" v-if="selectedWord=='all'">
          <template v-for="exhibitor in filteredExhibitors" :key="exhibitor.id">
            <exhibitor-item :exhibitor="exhibitor" v-if="exhibitor"></exhibitor-item>
          </template>
        </div>
          <div class="row row-cols-1 row-cols-md-3 row-cols-lg-4 row-cols-xl-6 g-0 g-md-4 row-eq-height justify-content-start" v-if="selectedWord!='all'">
              <div class="col-12" style="width:100vw; padding-left:15px">
           <h3 class="my-0">{{selectedWord}}</h3>
          </div> 
          <template v-for="exhibitor in filteredbyLetter" :key="exhibitor.id">
            <exhibitor-item :exhibitor="exhibitor" v-if="exhibitor" ></exhibitor-item>
          </template>
        </div>
      </div>

    </ion-content>
  </base-layout>
</template>
<script>
import {alertController, IonContent, IonSearchbar} from "@ionic/vue";
import {defineComponent} from "vue";
import {mapActions, mapState} from "vuex";
import ExhibitorItem from "@/components/ExhibitorItem";

export default defineComponent({
  name: "Exhibitors",
  data() {
    return {
      exhibitors: [],
      search: "",
      filtered: [],
      selectedWord: 'all',
      filteredbyLetter: [],

    };
  },
  components: {
    IonContent,
    IonSearchbar,
    ExhibitorItem
  },
  methods: {
    ...mapActions("exhibitors", ["getList"]),
    ...mapActions('googleanalytics', ['trackWithLabel']),

      filterbyLetter(word){
      this.selectedWord = word;
      let companies =  this.exhibitors;
      this.filteredbyLetter = companies.filter(company => {
        if(!company.isLetter){
          console.log(word)
          console.log(company.name.charAt(0))
         return !company.isLetter && company.name.charAt(0) == word;
         }
      });
        

    },
    filter(term) {
      if (term.length > 0) {
        this.filtered = this.exhibitors.filter(exhibitor =>
            Object.keys(exhibitor).some(k =>
                exhibitor[k]
                    ? exhibitor[k].toString().toLowerCase().includes(term.toLowerCase())
                    : ""
            )
        );
      } else {
        this.filtered = [];
      }
    },
    groupList(list, selector) {
      let letters = [];
      let currentCharCode = 'A'.charCodeAt(0) - 100;
      let sortedList = [];

      function addLetter(code) {
        let letter = String.fromCharCode(code);
        sortedList.push({
          isLetter: true,
          letter: letter
        });
        letters.push(letter);
      }

      (list || []).forEach(function (item) {
        if (item[selector]) {
          let itemCharCode = item[selector].toUpperCase().charCodeAt(0);
          let difference = itemCharCode - currentCharCode;
          if (difference > 0)
            addLetter(itemCharCode);
          currentCharCode = itemCharCode;
          sortedList.push(item);
        }
      });

      return sortedList;
    },
    async showDisclaimer(exhibitor) {
      if (exhibitor.disclaimer && exhibitor.disclaimer.length > 10) {
        const alert = await alertController
            .create({
              cssClass: 'my-custom-class',
              header: exhibitor.name,
              message: '<strong>' + exhibitor.disclaimer + '</strong>',
              buttons: [
                {
                  text: 'Decline',
                  role: 'cancel',
                  cssClass: 'secondary',
                  id: 'cancel-button',
                  handler: blah => {
                    console.log('Confirm Cancel:', blah)
                  },
                },
                {
                  text: 'Accept',
                  id: 'confirm-button',
                  handler: () => {
                    this.trackWithLabel(exhibitor.name);
                    if (exhibitor.disclaimer_link) {
                      window.open(exhibitor.disclaimer_link, '_blank', 'location=no');
                    } else {
                      this.$router.push('/app/exhibitors/' + exhibitor.id);
                    }
                  },
                },
              ],
            });
        return alert.present();
      } else if (exhibitor.disclaimer_link && exhibitor.disclaimer_link.length > 10) {
        window.open(exhibitor.disclaimer_link, '_blank', 'location=no');
      } else {
        this.$router.push('/app/exhibitors/' + exhibitor.id);
      }

    }
  },
  computed: {
    ...mapState('wordings', ['wordings', 'currentLanguage']),

    filteredExhibitors: function () {
      let returnList = this.filtered.length ? this.filtered : this.exhibitors;
      return this.groupList(returnList, 'name')
    }
  },
  async created() {
    let list = await this.getList();
    list = list.sort((a, b) => {
      if (a.name) {
        return a.name.localeCompare(b.name);
      }
    });
    this.exhibitors = this.groupList(list, 'name')
  },
  watch: {
    updateFinished: async function (newVal) {
      if (newVal) {
        let list = await this.getList();
        list = list.sort((a, b) => {
          if (a.name) {
            return a.name.localeCompare(b.name);
          }
        });
        this.exhibitors = this.groupList(list, 'name')
      }
    },
  },
});
</script>
<style lang="scss">
a {
  text-decoration: none;
}
</style>
